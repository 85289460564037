import React from 'react';

const Nav: React.FC = () => {
    return (
        <nav className="navbar is-warning" role="navigation" aria-label="main navigation">
  <div className="navbar-brand">
    <a className="navbar-item" href="/">
      <h1 className="title">Eli's Arcade</h1>
    </a>
  </div>
</nav>
    )
}

export default Nav;