import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

import "bulma/css/bulma.min.css";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ArcadeList from "./pages/ArcadeList";
import GamePage from "./pages/Game";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children:[
      {
        index: true,
        element: <ArcadeList />
      },
      {
        path: "/game/:id",
        element: <GamePage />
      }
    ]
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);