import React from 'react';
import {useParams} from "react-router-dom"
import gamesList from '../games.json'

const  GamePage: React.FC = () => {
    let {id} = useParams()
    const game = gamesList.find((v)=> v.id === id)
    const content = game ? <iframe
    src={`https://scratch.mit.edu/projects/${id}/embed`}
    allowTransparency={true}
    width="485"
    height="402"
    title={game.title}
    /> :
    <div className="title">Unable to find game</div>
    return (
        <div className='mt-5'>
        <h1 className='title is-6'>{game?.title}</h1>
        {content}
        </div>
        
    )
}

export default GamePage;
