import React from "react";
import {
 Outlet
} from "react-router-dom";
import "./App.css";
import Nav from './components/Nav'

const App = () => {
  
  return (
    <div>
      <div className="App">
        <Nav />
        <div className="container">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default App;