import React, {useState } from 'react';
import gamesList from '../games.json'
import {Game} from '../models'

const  ArcadeList: React.FC = () => {
    const [games]  = useState<Array<Game>>(gamesList);

    return (
        <div className="ArcadeList mt-5">
            {games?.map((game, i) => (
                <article key={i} className="notification is-link">
                     
                        <a className="subtitle is-4" href={`/game/${game.id}`}>{game.title}</a>
                    
                    {game.description ? <div className="message-body">{game.description}</div> : undefined}
                </article>
            ))}
        </div>
        
    )
}

export default ArcadeList;